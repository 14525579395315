<template>
  <main v-if="vendor && design" class="bg-checkout" id="account">
    <NavBar :redirect="'select'"
      :border="true"
      :isButton="true"
      back="select"
      v-if="getUser()"
    ></NavBar>
    <div class="all-content" v-if="getUser()">
      <div class="container">
        <notifications group="notifyAccount" position="center right"/>
        <div class="wrapper-accunt" v-if="user" >
          <h2 class="top-title">{{ $t('myBooking') }}</h2>
          <div class="box-top">
            <div class="user-box" >
              <div class="text-accuont user">{{user.displayName}}</div>
              <div class="text-accuont">{{user.email}}</div>
              <div class="text-accuont bandera-text ">
                <img v-if="user.bandera" :src="user.bandera" class="bandera-account" >
                <span v-if="user.indicativo" >({{user.indicativo}})</span>
                {{user.phone}}
              </div>
            </div>
            <div @click="isEditingProfile = true" v-if="((user.vendors === undefined || Object.keys(user.vendors).length === 0) || user.isAdmin)">
              <a href="#" @click="actualizarUser" class="text-accuont update-user-btn" >
                <div class="text-accuont"><i class="fa fa-pencil"></i>{{ $t('updateField') }}</div>
              </a>
            </div>
          </div>

        </div>
          <div>
            <div class="listTable" v-if="reservationsList">
              <CustomStyles>
                .theme--light.v-table thead th, td p {
                  color: {{ design.colorCheckout + '!important'}}
                }
                .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
                  background: 'transparent!important' }};
                }
              </CustomStyles>
              <div class="reservations">
              <div v-for="(item , index) in reservationsList" :key="item.id" class="Resrvations-item-list" :class="{'striped': (index % 2 != 0) }" >
                <div class="l-area">
                                <div class="intro-items bold" :style="[
                                    (parseInt(item.date) > now.getTime()) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                    (item.isSeated === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                    (item.isNoshow === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                    {color: design.colorCheckout}
                                  ]"
                                >
                                  <iconCalendar
                                    :fill="design.colorCheckout"
                                    width="15px"
                                    height="15px"
                                  ></iconCalendar>
                                  {{ parseInt(item.date) | moment('timezone', vendorTimezone, 'DD / MMM') }}
                                </div>
                                <div class="intro-items bold" :style="[
                                    (parseInt(item.date) > now.getTime()) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                    (item.isSeated === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                    (item.isNoshow === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                    {color: design.colorCheckout}
                                  ]"
                                >
                                  <iconTime
                                    :fill="design.colorCheckout"
                                    width="15px"
                                    height="15px"
                                  ></iconTime>
                                  {{ parseInt(item.date) | moment('timezone', vendorTimezone, 'hh:mm a') }}
                                </div>
                                  <div class="intro-items dis-name"
                                    :style="[
                                      (parseInt(item.date) > now.getTime()) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                      (item.isSeated === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                      (item.isNoshow === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'opacity': 0.4 },
                                      {color: design.colorCheckout}
                                    ]"
                                  >
                                  <iconPeople
                                      :fill="design.colorCheckout"
                                      width="15px"
                                      height="15px"
                                    ></iconPeople>
                                    <span class="nunber">{{ item.people  }} </span>
                                    <span class="name">{{ item.displayName }} </span>
                                    <span v-if="item.eventId" class="event"><i class="fa fa-star"></i></span>
                                    <span v-if="item.priceGiftCard > 0" class="event">
                                      <i class="iconGift"></i>
                                      <p class="textCard">{{ $t('haveBonus') }} &nbsp;</p><p>{{item.priceGiftCard | currency('$', 0, { thousandsSeparator: ',' })}}</p>
                                    </span>
                                  </div>
                                </div>
                                <div class="r-area">
                                <a v-if="vendor.id != 231" class="intro-items" @click.prevent.stop="setDataEditReservation(item)"
                                  :style="[
                                    (parseInt(item.date) > now.getTime()) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'pointer-events': 'none', 'opacity': 0.4 },
                                    (item.isSeated === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'pointer-events': 'none', 'opacity': 0.4 },
                                    (item.isNoshow === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'pointer-events': 'none', 'opacity': 0.4 },
                                    {color: design.colorCheckout}
                                  ]"
                                >
                                  <i class="fa fa-pencil" :class="{ disabled: validateTimeUpdate(item.date, params.updateTime) && item.balancePaid !== null }" :style="{'color': design.colorCheckout + '!important'}"></i>{{ $t('modify') }}
                                </a>
                                <a  @click="setDataCancelarReservation(item)" class="intro-items"
                                  :style="[
                                    (parseInt(item.date) > now.getTime()) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'pointer-events': 'none', 'opacity': 0.4 },
                                    (item.isSeated === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'pointer-events': 'none', 'opacity': 0.4 },
                                    (item.isNoshow === null) ? '' : { 'background-color': design.bgColorCheckout + '!important', 'pointer-events': 'none', 'opacity': 0.4 },
                                    {color: design.colorCheckout}
                                  ]"
                                >
                                  <i class="fa fa-times" :class="{ disabled: validateTimeUpdate(item.date, params.cancelledTime) && item.balancePaid !== null }" :style="{'color': design.colorCheckout + '!important'}"></i>{{ $t('cancel') }}
                                </a>
                            </div>
                            </div>
              </div>
              <p v-if="reservationsList.length <= 0" class="text-center-no-reservations" ><i class="fa fa-exclamation-triangle"></i>
                {{ $t('noAssotaciatedR') }}
              </p>
            </div>
            <notifications group="notify" position="right center"/>
          </div>
          <ModalWaitingList v-if="popupWaitingList" v-model="popupWaitingList"></ModalWaitingList>
          <ModalSuccessWaitingList v-if="popupSuccessWaitingList" v-model="popupSuccessWaitingList"></ModalSuccessWaitingList>
          <EditReservation v-if="editReservation" :type="'normal'" ></EditReservation>
          <UpdateDataUser :designUpd="design" ></UpdateDataUser>
          <politicaConfirmationModal v-if="design" :colorCheckout="design.colorCheckout" ></politicaConfirmationModal>
      </div>
    </div>
    <footerC v-if="vendor && design && getUser()"></footerC>
  </main>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import NavBar from '@/components/Select/navBar';
import { mapGetters } from 'vuex';
import footerC from '@/components/Account/footer';
import UpdateDataUser from '@/components/Account/updateDataUser';
import iconCalendar from '@/components/Icons/iconCalendar';
import iconTime from '@/components/Icons/iconTime';
import iconPeople from '@/components/Icons/iconPeople';
import EditReservation from '@/components/Account/editReservation';
import CancelarReservation from '@/components/Account/cancelarReservation';
import politicaConfirmationModal from '@/components/Checkout/politicaConfirmationModal';
import ModalWaitingList from '@/components/Select/ModalWaitingList.vue';
import ModalSuccessWaitingList from '@/components/Select/ModalSuccessWaitingList.vue';
import CustomStyles from '@/components/customStyles';
import authTypes from '@/store/types/auth';

export default {
  name: 'account',
  components: {
    NavBar,
    footerC,
    iconCalendar,
    iconTime,
    iconPeople,
    EditReservation,
    UpdateDataUser,
    politicaConfirmationModal,
    CustomStyles,
    ModalWaitingList,
    ModalSuccessWaitingList
  },
  data () {
    return {
      headers: [
        { text: 'Fecha', sortable: false, align: 'center' },
        { text: 'Hora', sortable: false, align: 'center' },
        { text: 'Personas', sortable: false, align: 'center' },
        { text: 'Nombre', sortable: false, align: 'left' },
        { text: 'Modificar', sortable: false, align: 'right' },
        { text: 'Cancelar', sortable: false, align: 'right' }
      ],
      update: false,
      now: new Date(),
      interval: null,
      nowServer: new Date()
    };
  },
  computed: {
    ...mapGetters({
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults],
      popupWaitingList: whiteLabelTypes.getters.popupWaitingList,
      popupSuccessWaitingList: whiteLabelTypes.getters.popupSuccessWaitingList
    })
  },
  methods: {
    serverTime () {
      this.$store.dispatch({
        type: 'global:getServerTime',
        data: {
          timezone: this.vendor.timezone
        }
      })
        .then(({ data }) => {
          this.nowServer = new Date(data.serverTime);
        });
    },
    setDataEditReservation (data) {
      this.$store.commit(
        reservationTypes.mutations.setSelectedDate,
        parseInt(data.date)
      );
      this.$store.commit(
        reservationTypes.mutations.setSelectedTypeReservation,
        data.typeReservation
      );
      let dayOfWeek = parseInt(this.$moment(parseInt(data.date)).seconds(0).minutes(0).hours(0).milliseconds(0).format('d'));
      let bol = this.validateTimeUpdate(parseInt(data.date), this.params.updateTime);
      let date = new Date(this.selectedDate);
      date = parseInt(this.$moment(date).hours(0).minutes(0).seconds(0).millisecond(0).format('x'));
      if (bol) {
        if (data.balancePaid === null || data.balancePaid === 0) {
          this.$store.commit(
            reservationTypes.mutations.setDataReservationEdit,
            data
          );
          this.$store.commit(
            reservationTypes.mutations.setSelectedPeople,
            parseInt(data.people)
          );
          this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
          this.$store.commit(
            reservationTypes.mutations.setSelectedHour,
            parseInt(data.date)
          );
          if (data.zoneId !== null) {
            this.$store.commit(reservationTypes.mutations.setSelectedZone, data.zoneId);
          }
          if (this.selectedTypeReservation === 'Rumba' && (dayOfWeek === 5 || dayOfWeek === 6)) {
            this.$store.commit(reservationTypes.mutations.setSelectedZone, data.zoneId);
            this.$store.commit(reservationTypes.mutations.setSelectedTable, data.tableId);
          }
          this.$store.commit(
            reservationTypes.mutations.setEditReservation,
            !this.editReservation
          );
        } else {
          this.$store.commit(
            whiteLabelTypes.mutations.setShowModalPolitica,
            true
          );
          this.$store.commit(
            whiteLabelTypes.mutations.setCancelUpdateTime,
            this.params.updateTime
          );
        }
      } else {
        this.$store.commit(
          reservationTypes.mutations.setDataReservationEdit,
          data
        );
        this.$store.commit(
          reservationTypes.mutations.setSelectedPeople,
          parseInt(data.people)
        );
        this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
        this.$store.commit(
          reservationTypes.mutations.setSelectedHour,
          parseInt(data.date)
        );
        if (data.zoneId !== null) {
          this.$store.commit(reservationTypes.mutations.setSelectedZone, data.zoneId);
        }
        if (this.selectedTypeReservation === 'Rumba' && (dayOfWeek === 5 || dayOfWeek === 6) && this.params.isMapped) {
          this.$store.commit(reservationTypes.mutations.setSelectedZone, data.zoneId);
          this.$store.commit(reservationTypes.mutations.setSelectedTable, data.tableId);
        }
        this.$store.commit(
          reservationTypes.mutations.setEditReservation,
          !this.editReservation
        );
      }
    },
    setDataCancelarReservation (data) {
      this.$store.commit(
        reservationTypes.mutations.setSelectedDate,
        parseInt(data.date)
      );
      this.$store.commit(
        reservationTypes.mutations.setSelectedHour,
        parseInt(data.date)
      );
      this.$store.commit(
        reservationTypes.mutations.setSelectedPeople,
        parseInt(data.people)
      );
      this.$store.commit(
        reservationTypes.mutations.setSelectedTypeReservation,
        data.typeReservation
      );
      if (data.balancePaid > 0) {
        let bol = this.validateTimeUpdate(data.date, this.params.cancelledTime);
        if (!bol) {
          this.$store.commit(
            reservationTypes.mutations.setCancelarReservation,
            data
          );
          this.openCancelModal();
        } else {
          this.$store.commit(
            whiteLabelTypes.mutations.setShowModalPolitica,
            true
          );
          this.$store.commit(
            whiteLabelTypes.mutations.setCancelUpdateTime,
            this.params.cancelledTime
          );
        }
      } else {
        this.$store.commit(
          reservationTypes.mutations.setCancelarReservation,
          data
        );
        this.openCancelModal();
      }
    },
    validateTimeUpdate (date, anticipationTime) {
      if (anticipationTime > date - this.now.getTime()) {
        return true;
      } else {
        return false;
      }
    },
    actualizarUser () {
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalUpdateUser,
        true
      );
    },
    colorCheckout () {
      return this.design.isDark === 1 ? '#fff' : '#000';
    },
    listReservation () {
      if (parseInt(this.idUser) === 13744) {
        window.location.href = '/select';
      } else {
        this.$store.dispatch({
          type: 'reservation:reservationsList',
          data: { userId: this.user.id, vendorId: this.$store.getters['whiteLabel:vendorId'] }
        });
      }
    },
    getUser () {
      if (window.localStorage.getItem('_userId') === '' || window.localStorage.getItem('_userId') == null || window.localStorage.getItem('_userId') === undefined || window.localStorage.getItem('_userId') === 13744) {
        return false;
      }
      return true;
    },
    openCancelModal () {
      let instance = this;
      this.$buefy.modal.open({
        parent: instance,
        props: {
          type: 'normal'
        },
        component: CancelarReservation,
        hasModalCard: true,
        canCancel: false,
        events: {
          cancel () {
            instance.isCancel = true;
          }
        }
      });
    }
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    this.$store.commit(authTypes.mutations.setUser);
    if (window.localStorage.getItem('_userId') === '' || window.localStorage.getItem('_userId') == null || window.localStorage.getItem('_userId') === undefined || window.localStorage.getItem('_userId') === 13744) {
      window.location.href = '/';
    }
    if (parseInt(this.idUser) === 13744) {
      window.location.href = '/';
    }
    let vendorId = { vendorId: this.$store.getters['whiteLabel:vendorId'] };
    this.loadScriptPersonalize(this.vendor.id);
    if (parseInt(this.idUser) !== 13744) {
      this.$store.dispatch({ type: 'reservation:schedules', data: vendorId });
      this.$store.dispatch({
        type: 'reservation:reservationsList',
        data: { userId: this.user.id, vendorId: this.$store.getters['whiteLabel:vendorId'] }
      });
      this.$store.dispatch({ type: 'event:allEvents', data: vendorId });
      this.$store.dispatch({ type: 'reservation:times', data: vendorId });
      this.interval = window.setInterval(this.listReservation, 30000);
    }
    this.serverTime();
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  },
  destroyed () {
    clearInterval(this.interval);
  },
  watch: {
    user () {
      if (parseInt(this.idUser) === 13744) {
        window.location.href = '/';
      }
    }
  }
};
</script>
<style lang="scss">
.top-title {
  color: var(--colorCheckout);
  font-size: 30px;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
  font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;

  &:after {
    position: absolute;
    background: var(--colorCheckout);
    height: 2px;
    width: 60px;
    content: " ";
    bottom: -10px;
    left: 0;
  }
}
.text-center-no-reservations {
  color: var(--colorCheckout);
  width: 100%;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid var(--colorCheckout);
}
body {
  background-size: cover;
}
main {
  display: flex;
  align-items: unset;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  min-height: 100vh;
}

.bg-checkout {
  background-position: center center;
  background-size: cover;
  background-position: top;
  background-image: var(--imgSelect);
  background-color: var(--bgColorCheckout);
}

.text-accuont {
  color: var(--colorCheckout);
}

.all-content {
  background: var(--bgColorCheckout);
  min-height: 75vh;
}
.bandera-account {
  width: 20px;
  margin-top: 1px;
  margin-right: 5px;
}
.bandera-text {
  line-height: 14px;
}
.wrapper-accunt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: flex-end;
  margin-top: 90px;
}
.box-top {
  width: 300px;
  border: 1px solid var(--colorCheckout);
  border-radius: 0.4rem;
}
.reservation-title {
  position: relative;
  width: 100px;
  height: auto;
}
.reservation-title span {
  position: absolute;
  bottom: 0;
}
.listTable {
  min-height: 38vh;
  margin-bottom: 30px;
  border: 1px solid var(--colorCheckout);
  padding: unset;
  border-radius: 4px;
  background-color: var(--bgColorCheckout);
  max-height: 39vh;
  overflow-y: scroll;
}
.disabled {
  color: gray;
}
.md-content {
  overflow: auto;
}
.btn-siguiente {
  height: 44px;
}

.user-box {
  padding: 10px;
  border-bottom: 2px solid var(--colorCheckout);
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  .user {
    font-size: 15px;
    font-weight: 600;
  }
}
.update-user-btn {
  width: 100%;
  display: flex;
  padding: 5px 15px;
  text-align: right;
  justify-content: center;
  font-weight: 600;
}
.Resrvations-item-list {
  &.striped {
    background: rgba(0, 0, 0, 0.2) !important;
  }
}
@media (max-width: 576px) {
  .container {
    padding: 10px;
  }

  .Resrvations-item-list {
    flex-direction: column;
  }

  .Resrvations-item-list .intro-items svg,
  .Resrvations-item-list .intro-items i {
    margin-right: 4px;
  }

  .Resrvations-item-list .r-area .intro-items {
    border-left: 1px solid var(--colorCheckout);
    font-weight: 500;
    width: 50%;
    border-top: 1px solid var(--colorCheckout);
    padding: 10px 5px;
  }

  .Resrvations-item-list .intro-items {
    padding: 15px 10px;
    font-size: 13px;
  }
  .Resrvations-item-list .intro-items {
    padding: 10px !important;
  }

  .wrapper-accunt {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
  .top-title {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 22px;
  }

  .box-top {
    width: 100%;
  }
  .top-title:after {
    left: calc(50% - 30px);
  }
}
.theme--light.v-table {
  background-color: transparent !important;
}
.theme--light.v-table thead th {
  font-weight: 700 !important;
  text-transform: uppercase;
}

.Resrvations-item-list {
  display: flex;
  border-bottom: 1px solid var(--color1);
  justify-content: space-between;
  text-align: center;
  .intro-items {
    color: var(--color1);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.bold {
      font-weight: 500;
    }
    svg,
    i {
      margin-right: 10px;
    }

    &.dis-name {
      .event {
        margin-left: 14px;
      }
      .nunber {
        margin-right: 14px;
        font-weight: 500;
      }
    }
  }
  .l-area {
    display: flex;
    align-items: center;
    justify-content: center;
    .intro-items {
      position: relative;
      &:after {
        position: absolute;
        content: " ";
        height: 100%;
        margin-top: 0;
        width: 1px;
        background: var(--color1);
        right: 0;
      }
      &:last-child {
        &:after {
          background: transparent !important;
        }
      }
    }
  }
  .r-area {
    display: flex;
    align-items: center;
    justify-content: center;
    .intro-items {
      border-left: 1px solid var(--color1);
      font-weight: 500;
    }
  }
}

.no-items {
  color: var(--color1);
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}
.event {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 500;
  }
}
.iconGift {
  display: flex;
  margin-right: 10px;
  -webkit-mask-repeat: no-repeat !important;
  mask-repeat: no-repeat !important;
  mask-position: center !important;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
  -webkit-mask-size: contain;
  mask-size: contain;
  background: var(--colorCheckout);
  -webkit-mask-image: url(../assets/images/icons/icon_gift.svg);
  mask-image:url(../assets/images/icons/icon_gift.svg);
}
@media(max-width: 414px) {
  .event {
    flex-direction: column;
    font-size: 11px;
    position: relative;
    padding-left: 14px!important;
    &:after {
      position: absolute;
      content: " ";
      height: calc(100% + 25px);
      margin-top: 0;
      width: 1px;
      background: var(--color1);
      left: 0;
    }
  }
  .iconGift {
    margin-right: 0px!important;
  }
  .textCard {
    display: none;
  }
}
</style>
